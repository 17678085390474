// Footer.scss

$primary-color: #000000;
$text-color: antiquewhite;
$accent-color: #ff79e9;

.footer {
  background-color: $primary-color;
  color: $text-color;
  padding: 3rem 0;
  font-family: Arial, sans-serif;

  .footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .footer-signup {
    flex: 0 0 40%;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    .signup-form {
      display: flex;
      flex-direction: column;

      input {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        border: none;
        background-color: rgba($text-color, 0.1);
        color: $text-color;

        &::placeholder {
          color: rgba($text-color, 0.7);
        }
      }

      button {
        padding: 0.5rem;
        border: none;
        background-color: $accent-color;
        color: $primary-color;
        cursor: pointer;
        transition: background-color 0.3s;
        text-transform: uppercase;

        &:hover {
          background-color: darken($accent-color, 10%);
        }
      }
    }
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    flex: 0 0 50%;

    .footer-section {
      h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          margin-bottom: 0.25rem;
          font-size: 0.9rem;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: 2rem auto 0;
    padding: 1rem;
    border-top: 1px solid rgba($text-color, 0.1);
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .footer {
    .footer-content {
      flex-direction: column;
    }

    .footer-signup,
    .footer-links {
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }

    .footer-links {
      flex-direction: column;

      .footer-section {
        margin-bottom: 1rem;
      }
    }

    .footer-bottom {
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        margin-bottom: 0.5rem;
      }

      @media (max-width: 768px) {
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}
