@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
.app {
}

.card-container {
  background-image: url("../../assets/images/website\ demo.png");
  background-size: cover;
  background-position-x: center;
  width: 100%;
  height: 100vh;
  padding: calc(2.5rem + 2.5vw) 0;
  background-color: #e083aa;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  // padding: 40px;
  font-family: "Playfair Display", serif;
  color: antiquewhite;
}
.title {
  color: #ffffff;
}
@media (max-width: 720px) {
  .app {
  }
  .card-container {
    display: flex;
    justify-content: center;
    padding: calc(2.5rem + 2.5vw) 0;
    flex-wrap: wrap;
    margin-top: 25px;
    height: 100%;
    text-align: center;
    font-family: "Playfair Display", serif;
    color: antiquewhite;
  }

  .p-text {
    color: antiquewhite;
    font-size: x-small;
    margin-top: 20px;
    max-height: fit-content;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.p-text {
  color: #fff1fe;
  font-size: larger;
  margin-top: 20px;
  max-height: fit-content;
  margin-left: 12vw;
  margin-right: 12vw;
}
