@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
.app-card {
  font-family: "Playfair Display", serif;
  color: #e083aa;
  margin-top: 25px;
}

.card-container1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: black;
  text-align: center;
  font-family: "Playfair Display", serif;
  background-color: black;
}

@media (max-width: 720px) {
  .card-container1 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
