.scroll-button {
  position: fixed;
  bottom: 30px; /* Distance from the bottom of the viewport */
  right: 30px; /* Distance from the right of the viewport */
  background-color: #ff79e9; /* Green background */
  color: white; /* White text */
  border: none;
  border-radius: 50%; /* Circular button */
  width: 50px;
  height: 50px;
  font-size: 24px; /* Larger font size for the arrow */
  // display: none; /* Initially hidden */
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition */
  z-index: 1000; /* Ensure it stays on top */
}

.scroll-up-button:hover {
  background-color: #ff79e9; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.goTop {
  display: block;
}

.goTopHidden {
  display: none;
}

@media (max-width: 720px) {
  .scroll-button {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}
