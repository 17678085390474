@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.sectiontest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: calc(0.5rem + 1.5vw) 0 calc(2.5rem + 2.5vw) 0;
  background-color: #e083aa;
  text-align: center;
  // padding: 40px;
  font-family: "Playfair Display", serif;
  color: antiquewhite;
  // background-color: aliceblue;
  .title {
    color: #000000;
    display: inline-block;
    font-size: 2rem;
    margin-bottom: 1rem;
    position: relative;
    &::before {
      content: "";
      height: 1px;
      width: 50%;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0.5rem);
      /* or 100px */
      border-bottom: 2px solid var(--pink);
    }
  }
  .carousel {
    width: 50%;
    overflow: hidden;
    transition: transform 0.3s;
    display: flex;
    background-color: #e083aa;
    align-items: center;
    color: #000000;
  }
  .cardtest {
    // height: 155px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
  }

  @media (max-width: 600px) {
    .cardtest {
      // height: 500px;
    }
    .carousel-indicators {
      bottom: -45px !important;
      color: #000000;
    }
  }

  .carousel-indicators {
    bottom: -25px !important;
  }

  .cardtest-title {
    font-size: 25px;
    margin-top: 5px;
    cursor: pointer;
    font-family: "Raleway", sans-serif;
    background-color: #e083aa;
    color: #000000;
  }

  .cardtest-description {
    font-size: 18px;
    color: #000000;
    cursor: pointer;
    font-family: "Open Sans", sans-serif; /* Use the imported font */
    background-color: #e083aa;
  }
}

@media (max-width: 720px) {
  .carousel {
    width: 100%;
  }
}
