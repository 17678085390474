@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.charter-sales-hero {
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  /* height: auto; */
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: left;
  color: white;
  padding-left: 4rem;
  /* position: absolute; */
  /* z-index: 100; */
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  /* display: flex; */
  /* align-items: center; */
  flex-direction: column;
  /* justify-content: center; */
  /* text-align: center; */
}

.banner {
  position: relative;
  height: 100vh;
  width: 100%;
  margin-bottom: -57px;
  font-family: "Palatino Linotype", serif;
}
/* ... rest of the CSS remains the same ... */

.hero-content {
  .logo-img {
    width: 30%;
    height: 30%;
  }
  margin-top: 5vw;
  max-width: 620px;
  padding: 20px;
  text-align: left;
}
.large-font {
  font-family: "Palatino Linotype", serif;
  font-size: 60px;
  font-weight: bolder;
  color: #fff1fe;
}

.reach-out {
  // background-color: #ffffff;
  // color: #0a0b10;
  padding: 0.5rem 0.7rem;
  margin-top: 1rem;
  // border-radius: 20px;
  cursor: pointer;
  font-size: calc(0.5rem + 0.5vw);
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: transform 0.2s;

  background: linear-gradient(to right, #93032e10, #520b2f10);
  color: #fff1fe;
  border: 3px solid;
  border-color: #520b2f;

  &:hover {
    background: linear-gradient(to right, #93032e, #520b2f);
  }

  img {
    width: 1.5rem;
  }
  @media only screen and (max-width: 48em) {
    padding: 0.2rem 1rem;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}

@media (max-width: 650px) {
  .large-font {
    font-size: 30px;
  }
  .hero-content {
    max-width: 350px;
  }
  .charter-sales-hero {
    padding-left: 1rem;
    height: 100vh;
  }
}

h1 {
  font-size: 3rem;
  margin-bottom: 0px;
}

p {
  font-size: 1.5rem;
  margin-bottom: 0px;
}
