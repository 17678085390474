@import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap");

/* General styles */
body {
  margin: 0 !important;
  font-family: Arial, sans-serif !important;
  padding: 0 !important;
  // padding-top: 60px !important; /* Ensure content doesn't get hidden behind fixed navbar */
}

// .navbar {
//   position: fixed !important;
//   top: 0 !important;
//   width: 100% !important;
//   display: flex !important;
//   justify-content: space-between !important;
//   align-items: center !important;
//   padding: 10px 20px !important;
//   // background-color: #1a1a1a !important;
//   // color: white !important;
//   // z-index: 1000 !important;
// }

.App-header {
  position: absolute;
}

.navbar1 {
  background-color: rgb(
    0,
    0,
    0,
    0
  ) !important; /* White color with 50% opacity */
  position: sticky !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0) !important;
  z-index: 1000 !important;
  width: 100vw !important;
  transition: transform 0.5s !important;
}

.navbar1.scrolled-down {
  transform: translateY(-100%) !important; /* Hide the navbar by moving it up */
}

.navbar1-container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.logo-text {
  opacity: 1 !important;
  filter: blur(0) !important;
  text-decoration: none !important;
  color: #fff1fe !important;
  display: block !important;
  position: relative !important;
  padding: 0px !important;
  font-size: 20px !important;
  font-weight: normal !important;
  font-family: "Inknut Antiqua", serif !important;
  color: antiquewhite !important;
  text-transform: uppercase !important;
  transition: 0.5s !important;
  border: none;
  background: #ffffff00 !important;
  margin-top: 2rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.flex-container {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  flex-wrap: wrap; /* Allow items to wrap if they don't fit */
  padding: 10px;
  text-align: left;
}

@media (max-width: 700px) {
  .logo-text {
    opacity: 1 !important;
    filter: blur(0) !important;
    text-decoration: none !important;
    color: #fff1fe !important;
    display: block !important;
    position: relative !important;
    padding: 0px !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-family: "Inknut Antiqua", serif !important;
    color: antiquewhite !important;
    text-transform: uppercase !important;
    transition: 0.5s !important;
    border: none;
    background: #ffffff00 !important;
    margin: 0rem;
  }
}
.navbar1-logo {
  margin-left: 10px !important;
  display: flex !important;
  align-items: center !important;
}

.logo-image {
  height: 65px !important;
  width: auto !important;
}

.navbar1-contact {
  display: flex !important;
  align-items: center !important;
  margin-right: 23px !important;
  cursor: pointer !important;
  margin-top: 5px !important;
}

.navbar1-phone {
  font-size: 14px !important;
  margin-right: 15px !important;
  color: white !important;
  text-decoration: none !important;
  margin-bottom: 10px !important;
}

#mobile i {
  color: #fff !important;
  font-size: 20px;
}

#mobile {
  display: none !important; /* Hide initially on larger screens */
  cursor: pointer !important;
}

.navbar1-links button:hover {
  color: #c2beb0 !important; /* Change text color on hover */
  text-decoration: underline !important; /* Underline on hover */
}

.navbar1-phone:hover {
  color: #c2beb0 !important; /* Change text color on hover */
  font-weight: bold !important;
}

@media (max-width: 1200px) {
  .navbar1 {
    width: 100vw !important;
  }
  .navbar1-links {
    position: fixed !important;
    top: 60px !important;
    right: 0px !important;
    width: 100% !important;
    background-color: #000000 !important;
    height: 100vh !important;
    z-index: 1000 !important;
    transform: translateX(100%) !important;
    opacity: 0 !important;
    transition: all 0.5s ease-out !important;
    display: none !important; /* Hide initially on smaller screens */
  }

  .navbar1-links.open {
    transform: translateX(0) !important;
    opacity: 1 !important;
    display: flex !important; /* Show when open */
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .navbar1-links ul {
    flex-direction: column !important;
    padding: 0 !important;
  }

  .navbar1-links li {
    margin: 10px 0 !important;
    width: 100% !important;
    text-align: center !important;
  }

  .navbar1-contact {
    width: 100% !important;
    justify-content: center !important;
  }
  .navbar1-links button:hover {
    color: antiquewhite !important; /* Change text color on hover */
    text-decoration: underline !important; /* Underline on hover */
  }
  .navbar1-phone:hover {
    color: antiquewhite !important; /* Change text color on hover */
  }

  #mobile {
    display: block !important; /* Display hamburger icon on smaller screens */
  }
}

@media (max-width: 1800px) {
  #mobile {
    margin-right: 25px !important;
  }
}

.navbar1-links {
  display: flex !important;
  list-style-type: none !important;
  // transition: all 1s ease-out !important;
}

.navbar1-links ul {
  display: flex !important;
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

// .navbar1-links li {
//   margin: 0 15px !important;
//   position: relative !important;
// }

// .navbar1-links a {
//   color: white !important;
//   text-decoration: none !important;
//   font-size: 14px !important;
// }

.navbar1-links ul li {
  list-style: none !important;
  margin: 0 20px !important;
  transition: 0.5s !important;
}

.navbar1-links ul li button {
  display: block !important;
  position: relative !important;
  text-decoration: none !important;
  padding: 5px !important;
  font-size: 18px !important;
  font-family: sans-serif !important;
  color: #ef82a4 !important;
  text-transform: uppercase !important;
  transition: 0.5s !important;
  border: none;
  background: #ffffff00 !important;
}

.navbar1-links ul li a {
  display: block !important;
  position: relative !important;
  text-decoration: none !important;
  padding: 5px !important;
  font-size: 18px !important;
  font-family: sans-serif !important;
  color: antiquewhite !important;
  text-transform: uppercase !important;
  transition: 0.5s !important;
  border: none;
  background: #ffffff00 !important;
}

.navbar1-links ul:hover li button {
  transform: scale(0.9) !important;
  opacity: 0.6 !important;
  filter: blur(2px) !important;
  text-decoration: none !important;
}

.navbar1-links ul:hover li a {
  transform: scale(0.9) !important;
  opacity: 0.6 !important;
  filter: blur(2px) !important;
  text-decoration: none !important;
}

.navbar1-links ul li button:hover {
  transform: scale(1.2) !important;
  opacity: 1 !important;
  filter: blur(0) !important;
  text-decoration: none !important;
  color: #ef82a4 !important;
  font-weight: normal !important;
}

.navbar1-links ul li a:hover {
  transform: scale(1.2) !important;
  opacity: 1 !important;
  filter: blur(0) !important;
  text-decoration: none !important;
  color: antiquewhite !important;
  font-weight: normal !important;
}

.navbar1-links ul li button:before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  // background: antiquewhite !important;
  transition: 0.5s !important;
  transform-origin: right !important;
  transform: scaleX(0) !important;
  z-index: -1 !important;
  text-decoration: none !important;
}

.navbar1-links ul li a:before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  // background: antiquewhite !important;
  transition: 0.5s !important;
  transform-origin: right !important;
  transform: scaleX(0) !important;
  z-index: -1 !important;
  text-decoration: none !important;
}

.navbar1-links ul li button:hover:before {
  transition: transform 0.5s !important;
  transform-origin: left !important;
  transform: scaleX(1) !important;
  text-decoration: none !important;
}

.navbar1-links ul li a:hover:before {
  transition: transform 0.5s !important;
  transform-origin: left !important;
  transform: scaleX(1) !important;
  text-decoration: none !important;
}

.pink-text {
  color: #e29cb2;
}
