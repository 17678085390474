@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
// .app1 {
//   text-align: center;
//   padding: 30px;
//   font-family: "Playfair Display", serif;
//   background-color: black;
//   margin-top: 100px;
// }

.section {
  width: 100%;
  padding: calc(2.5rem + 2.5vw) 0;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  font-family: "Playfair Display", serif;

  .h1 {
    color: var(--white);
    display: inline-block;
    font-size: 2rem;
    position: relative;
    font-family: "Playfair Display", serif;
    &::before {
      content: "";
      height: 1px;
      width: 50%;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0.5rem);
      /* or 100px */
      border-bottom: 2px solid var(--pink);
    }
  }

  .icon {
    display: flex;
    margin-bottom: 3rem;
    a {
      &:hover {
        img {
          filter: invert(20%) sepia(100%) saturate(500%) hue-rotate(580deg)
            brightness(100%) contrast(97%);
        }
      }
      &:not(:last-child) {
        margin-right: 2rem;
      }
      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      padding: 1rem calc(0.5rem + 1vw);
      margin-bottom: 1rem;
      background-color: grey;
      border: none;
      border-radius: 4px;
      color: #eff7f8;
      &:active,
      &:focus {
        border: none;
        outline: none;
        background-color: grey;
      }
      &::placeholder {
        color: #eff7f8;
        opacity: 0.6;
      }
      &[name="name"] {
        margin-right: 2rem;
      }
    }
    textarea {
      padding: 1rem calc(0.5rem + 1vw);
      margin-bottom: 1rem;
      background-color: grey;
      border: none;
      border-radius: 4px;
      color: #eff7f8;
      margin-bottom: 2rem;
      &:focus,
      &:active {
        background-color: grey;
      }
      &::placeholder {
        color: #eff7f8;
        opacity: 0.6;
      }
    }
    button {
      padding: 0.8rem 2rem;
      background-color: white;
      border-radius: 20px;
      font-size: 1.2rem;
      color: #0a0b10;
      cursor: pointer;
      transition: transform 0.3s;
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }

  .iframe {
    width: 700px;
    height: 420px;
  }
}

// @media (max-width: 40em) {
//   .row {
//     display: flex;
//     flex-direction: column;
//     input {
//       &[name="name"] {
//         margin-right: 0;
//       }
//     }
//   }
// }

// @media (max-width: 720px) {
//   .section {
//     .iframe {
//       width: 100%;
//       height: 260px;
//     }
//   }
// }

.contact-section {
  width: 100%;
  // padding: calc(2.5rem + 2.5vw) 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  font-family: "Playfair Display", serif;
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
}

.title1 {
  color: #ef82a4;
  display: inline-block;
  font-size: 2rem;
  margin-bottom: 3rem;
  position: relative;
  &::before {
    content: "";
    height: 1px;
    width: 50%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0.5rem);
    /* or 100px */
    border-bottom: 2px solid var(--pink);
  }
}

.icons {
  display: flex;
  margin-bottom: 3rem;
  a {
    &:hover {
      img {
        filter: invert(20%) sepia(100%) saturate(500%) hue-rotate(580deg)
          brightness(100%) contrast(97%);
      }
    }
    &:not(:last-child) {
      margin-right: 2rem;
    }
    img {
      width: 3rem;
      height: 3rem;
    }
  }
}

.form-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    padding: 1rem calc(0.5rem + 1vw);
    margin-bottom: 1rem;
    background-color: #9dd7ac;
    border-radius: 10px;
    border-color: #520b2f;
    color: #520b2f;
    &:active,
    &:focus {
      border: none;
      outline: none;
      background-color: #9dd7ac;
    }
    &::placeholder {
      color: #520b2f;
      opacity: 0.6;
    }
    &[name="name"] {
      margin-right: 2rem;
    }
  }
  textarea {
    padding: 1rem calc(0.5rem + 1vw);
    margin-bottom: 1rem;
    background-color: #9dd7ac;
    border-color: #520b2f;
    border-radius: 10px;
    color: #520b2f;
    margin-bottom: 2rem;
    &:focus,
    &:active {
      background-color: #9dd7ac;
    }
    &::placeholder {
      color: #520b2f;
      opacity: 0.6;
    }
  }
  button {
    background-color: #ef82a4;
    padding: 0.8rem 4rem;
    border-radius: 20px;
    font-size: 1.2rem;
    color: #0a0b10;
    cursor: pointer;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(0.9);
    }
  }
}

.contact-row {
  @media only Screen and (max-width: 40em) {
    display: flex;
    flex-direction: column;
    .form-style {
      input {
        margin-right: 0rem !important;
        width: 100%;
      }
    }
  }
}

@media only Screen and (max-width: 40em) {
  .form-style {
    input {
      margin-right: 0rem !important;
      width: 100%;
    }
    textarea {
      width: 100%;
    }
  }
}
