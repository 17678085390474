@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
// .app1 {
//   text-align: center;
//   padding: 30px;
//   font-family: "Playfair Display", serif;
//   background-color: black;
//   margin-top: 100px;
// }

.section {
  width: 100%;
  padding: calc(1.5rem + 1.5vw) 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  // padding: 40px;
  font-family: "Playfair Display", serif;
  color: antiquewhite;

  .iframe {
    width: 72vw;
    height: 40vw;
  }
}

@media (max-width: 720px) {
  .section {
    .iframe {
      width: 100%;
      height: 60vw;
    }
  }
}
