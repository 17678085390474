.main-hero {
  background-color: antiquewhite;
  padding: 100px 0;
  text-align: center;
}

.home-content {
  // padding: 20px;
  background-color: black;
  color: antiquewhite;
}
