@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.card {
  width: 20vw;
  overflow: hidden;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  background-color: black;
  margin: 10px;
}

.card:hover {
  transform: translateY(-5px);
}
.card:hover .card-title,
.card:hover .card-description {
  text-decoration: underline; /* Underline the title and description on hover */
}

.card-image-container {
  width: 100%;
  height: 50%; /* Allocate 60% of the card height to the image container */
  overflow: hidden; /* Ensure the image fits within the container */
  cursor: pointer;
  background-color: black;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fits within the container */
}

.card-content {
  padding: 0%;
  text-align: center;
  height: 50%; /* Allocate 40% of the card height to the content */
  display: flex;
  flex-direction: column;
  background-color: black;
  color: #9dd7ac;
}

.card-title {
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 5px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  background-color: black;
}

.card-description {
  font-size: 14px;
  color: #121010;
  cursor: pointer;
  font-family: "Open Sans", sans-serif; /* Use the imported font */
  background-color: black;
  color: antiquewhite;
}

@media (max-width: 1200px) {
  .card {
    width: 30vw;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    background-color: black;
  }
}

@media (max-width: 450px) {
  .card {
    width: 60vw;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    background-color: black;
  }
}
